import React, { useState, useEffect } from 'react';
import { color, motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [school, setSchool] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const academicQuery = '*[_type == "school"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });

    client.fetch(academicQuery).then((data) => {
      setSchool(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experiências TI</h2>
      {skills.length > 0 ? ( 
        <div className="app__skills-container">
          <motion.div className="app__skills-list">
            {skills.map((skill) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-item app__flex"
                key={skill.name}
              >
                <div
                  className="app__flex"
                  style={{ backgroundColor: skill.bgColor }}
                >
                  <img src={urlFor(skill.icon)} alt={skill.name} />
                </div>
                <p className="p-text">{skill.name}</p>
              </motion.div>
            ))}
          </motion.div>
          <div className="app__skills-exp">
            {/* Academic Experiences */}
            <div className="app__skills-exp-work">
              <h1 style={{ textAlign: 'start', color: '#0B6623' }}>Académicas</h1>
              {school.map((academic) => (
                <motion.div
                  className="app__skills-exp-item"
                  key={academic.year}
                >
                  <div className="app__skills-exp-year">
                    <p className="bold-text" style={{ textAlign: 'start', color: '#556B2F' }}>{academic.year}</p>
                  </div>
                  <motion.div className="app__skills-exp-works">
                    {academic.schoolName.map((schoolName) => (
                      <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__skills-exp-work"
                        data-tip
                        data-for={schoolName.name}
                        key={schoolName.name}
                      >
                        <h4 className="bold-text">{schoolName.schoolName}</h4>
                        <p className="p-text">{schoolName.degree}</p>
                      </motion.div>
                    ))}
                  </motion.div>
                </motion.div>
              ))}
            </div>
            {/* Work Experiences */}
            <div className="app__skills-exp-work">
              <h1 style={{ textAlign: 'start', color: '#0B6623' }}>Trabalho</h1>
              {experiences.map((experience) => (
                <motion.div
                  className="app__skills-exp-item"
                  key={experience.year}
                >
                  <div className="app__skills-exp-year">
                    <p className="bold-text" style={{ textAlign: 'start', color: '#556B2F' }}>{experience.year}</p>
                  </div>
                  <motion.div className="app__skills-exp-works">
                    {experience.works.map((work) => (
                      <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__skills-exp-work"
                        data-tip
                        data-for={work.name}
                        key={work.name}
                      >
                        <h4 className="bold-text">{work.name}</h4>
                        <p className="p-text">{work.company}</p>
                        <p className="p-text" style={{color: 'gray', fontSize: "0.90rem"}}>{work.desc}</p>
                      </motion.div>
                    ))}
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-text">Nenhuma Skills & Experiências disponíveis de momento.</div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__greybg',
);
