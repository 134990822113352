import React, { useEffect, useState } from 'react';
import './TypingTextAnimation.scss'; // Ensure you have an SCSS or CSS file for basic styling

const TypingTextAnimation = () => {
  const phrases = ['Desenvolvedor','Designer', 'Estudante', 'Engenheiro', 'o Adão Araújo'];
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    let timer;
    const currentPhrase = phrases[phraseIndex];

    if (isDeleting) {
      // Erase letters
      timer = setTimeout(() => {
        setDisplayedText(currentPhrase.substring(0, displayedText.length - 1));
        setTypingSpeed(200);
      }, typingSpeed);
    } else {
      // Type letters
      timer = setTimeout(() => {
        setDisplayedText(currentPhrase.substring(0, displayedText.length + 1));
        setTypingSpeed(200);
      }, typingSpeed);
    }

    if (!isDeleting && displayedText === currentPhrase) {
      // Pause before starting to erase text
      setIsDeleting(true);
      setTypingSpeed(2000);
    } else if (isDeleting && displayedText === '') {
      // Move to the next phrase or loop back to the beginning
      setIsDeleting(false);
      setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      setTypingSpeed(500);
    }

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, phraseIndex, phrases]);

  return (
    <div className="typing-text-animation">
      {displayedText}
      <span className="cursor">|</span>
    </div>
  );
};

export default TypingTextAnimation;