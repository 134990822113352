import React from 'react';
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a className="app__social" href='https://www.linkedin.com/in/ad%C3%A3o-ara%C3%BAjo-4427871a0/'>
        <FaLinkedin />
      </a>
    </div>
    <div>
      <a className="app__social" href='https://github.com/StrongHack'>
        <FaGithub />
      </a>
    </div>
  </div>
);

export default SocialMedia;