import React from 'react'
import { motion } from 'framer-motion'

import { images } from '../../constants'
import { Card } from '../../components'
import TypingTextAnimation from './TypingTextAnimation'
import './Header.scss';

const Header = () => {
  return (
    <div id="home" className='app__header app__flex'>
      <motion.div
        whileInView={{ x:[-100,0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className='app__header-info'
      >
          <div className='app__flex'>
            <div>
              <h1 className='head-text'>👋 Olá, Sou <TypingTextAnimation/></h1>
            </div>
          </div>

            <div className=' app__flex'>
              <p className='p-text'>À medida que me aproximo do final do meu curso de engenharia informática, estou ansioso por aplicar os conhecimentos e habilidades adquiridos para resolver problemas do mundo real, especialmente em áreas como desenvolvimento de software, IA e cibersegurança, onde posso fazer um impacto significativo através da inovação e do pensamento crítico.</p>
          </div>

          <div className='app__flex'>
            <a href="CVAdãoAraújo.pdf" download className='app__btn'>Descarregar CV</a>
          </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__header-img'
      >
        <Card>
          <img src={images.profile} alt="Profile Image" />
        </Card>
      </motion.div>
    </div>
  )
}

export default Header
